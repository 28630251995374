<template>
  <div>
    <section
      class="section"
      style="padding-bottom: 0"
    >
      <!-- START OF MAIN BANNER -->
      <div class="container">
        <section class="banner">
          <div class="container">
            <div class="banner-img">
              <img
                alt="Banner"
                class="img-fluid"
                src="../assets/images/mainbanner.png"
              >
            </div>

            <div class="banner-text animate__animated animate__fadeInLeft">
              <h1>
                We provide<br>
                <span>solutions</span><br>
                for your business
              </h1>

              <div>
                <a
                  class="btn"
                  @click="handleClicBtn('ourSolutions')"
                >Find Out More </a>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- END OF MAIN BANNER -->
    </section>
    <!-- START OF WHY CHOOSE STACK TECH - TWO ROWS -->
    <section class="whychoose-content">
      <div class="container">
        <div class="col-md-12">
          <h1>Why Choose Stack Tech</h1>
          <!-- START OF FIRST ROW -->

          <div class="row">
            <!-- METHODOLOGIES -->
            <div class="col-md-4">
              <div class="card">
                <img
                  alt="Methodologies"
                  class="icon"
                  src="../assets/images/icon-methodologies.png"
                >
                <h2>Methodologies</h2>

                <p>
                  We adopt Scrum to track the project progress every two weeks, ensuring
                  that the requirements are delivered promptly and presented to
                  stakeholders to minimise misinterpretation/miscommunication. We also
                  deploy DevOps tools to assist in the project management, development and
                  maintenance
                </p>
              </div>
            </div>
            <!-- EXPERIENCE -->

            <div class="col-md-4">
              <div class="card">
                <img
                  alt="Experience"
                  class="icon"
                  src="../assets/images/icon-experience.png"
                >
                <h2>Experience</h2>

                <p>
                  Our Team Leaders are veterans that have been in the industry for several
                  years, providing mostly B2B and in-house solutions. Our team members are
                  also well diversed in various aspects to provide insights to the project
                  design and development.
                </p>
              </div>
            </div>
            <!-- CONNECT WITH US -->

            <div class="col-md-4">
              <div class="card">
                <img
                  alt="Connect With Us"
                  class="icon"
                  src="../assets/images/icon-connect-with-us.png"
                >
                <h2>Connect with us</h2>

                <p>
                  We are here to assist in your digitialisation journey to improve your
                  business efficiency.
                </p>
              </div>
            </div>
          </div>
          <!-- END OF FIRST ROW --><!-- START OF SECOND ROW -->

          <div class="row second">
            <!-- ON-TIME ASSISTANCE -->
            <div class="col-md-4">
              <div class="card">
                <img
                  alt="On-time Assistance"
                  class="icon"
                  src="../assets/images/icon-on-time-assistance.png"
                >
                <h2>On-time Assistance</h2>

                <p>
                  We will be in constant contact with your team to ensure there are no
                  gaps in information. Your project has a dedicated Team Leader to handle
                  things in a seamless manner. We make sure your inquiries are answered as
                  quickly as possible. We are available at your service during standard
                  business hours.
                </p>
              </div>
            </div>
            <!-- ON-TIME DELIVERY -->

            <div class="col-md-4">
              <div class="card">
                <img
                  alt="On-time Delivery"
                  class="icon"
                  src="../assets/images/icon-on-time-delivery.png"
                >
                <h2>On-time Delivery</h2>

                <p>
                  At StackTech, we are a company that understands the need for timely
                  delivery. Businesses need to be fast when trying to build market
                  presence. That&#39;s why our company makes sure that things work in a
                  seamless way, without any unnecessary problems on the go.
                </p>
              </div>
            </div>
            <!-- END OF SECOND ROW -->
          </div>
        </div>
      </div>
    </section>
    <!-- END OF WHY CHOOSE STACK TECH - TWO ROWS --><!-- START OF ABOUT STACK TECH -->

    <section class="aboutus-content">
      <div class="container">
        <h1>About Stack Tech</h1>

        <p>
          We are a SAAS product and solution provider established in 2023 and located in Ho Chi Minh City. We focus on providing innovative SAAS products and customized solutions to customers in target industries such as gaming and retail. We guide our clients through the software development life cycle. The founding team is from Singapore and has 15 years of experience in the SAAS industry.
        </p>
        <!-- START OF BTN CONTAINER -->

        <div class="btn-container">
          <div class="btn">
            <a @click="handleClicBtn('aboutUs')">Find Out More </a>
          </div>
        </div>
        <!-- END OF BTN CONTAINER -->
      </div>
    </section>
    <!-- END OF ABOUT STACK TECH --><!-- START OF FOOTER -->
  </div>
</template>

<script>
import goldline from "@/assets/images/goldline.png";
import aven from "@/assets/images/aven.png";
import kanba from "@/assets/images/kanba.png";
import kyan from "@/assets/images/kyan.png";
import spotify from "@/assets/images/spotify.png";
import Surface from "@/assets/images/Surface.png";
import dashboard from "@/assets/images/Dashboard.png";
import Image39 from "@/assets/images/Image-39.png";
export default {
  name: "Home",
  components: {
    // CarouselCopy,
  },
  data() {
    return {
      goldline,
      aven,
      kanba,
      kyan,
      spotify,
      Surface,
      dashboard,
      Image39,
      height: "100%",
    };
  },
  mounted() {
    // window.addEventListener("resize", this.changeHeight);
    // this.changeHeight();
  },
  methods: {
    changeHeight() {
      this.height = `${this.$refs.dashboardImg.offsetHeight + 80}px`;
    },
    handleClicBtn(routeName) {
      this.$router.push({
        name: routeName
      })
    }
  },
};
</script>

<style lang="less" scoped></style>
