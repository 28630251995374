<template>
  <div>
    <GlobalHeader :footer-active-tab="footerActiveTab" />
    <section class="hero-5-bg position-relative">
      <router-view />
    </section>
    <GlobalFooter @handleClickFooter="handleClickFooter" />
  </div>
</template>

<script>
import GlobalHeader from '@/components/GlobalHeader'
import GlobalFooter from '@/components/GlobalFooter'

export default {
  name: 'BasicLayout',
  components: {
    GlobalHeader,
    GlobalFooter
  },
  data () {
    return {
      footerActiveTab: Number(sessionStorage.getItem("activeTab")) || 0,
    }
  },
  computed: {
  },
  created () {
  },
  mounted () {
  },
  methods: {
    handleClickFooter(footerActiveTab) {
      this.footerActiveTab = footerActiveTab;
    }
  }
}
</script>