<template>
  <div>
    <footer>
      <div class="container">
        <div class="row">
          <div class="col-md-5 quicklinks">
            <h3>Quicklinks</h3>

            <ul class="links">
              <li
                v-for="(item, index) in tabList"
                :key="index"
                @click="handleClickTab(item, index)"
              >
                <a :class="activeTab === index ? 'active' : ''">{{ item.name }}</a>
              </li>
            </ul>
          </div>

          <div class="col-md-7 info">
            <div>
              <h3>Stack Tech</h3>

              <p>
                <i class="bi bi-geo-alt" />
                <a @click="jumpMap">Số 9 Nguyễn Cơ Thạch, Phường An Lợi Đông, Tp. Thủ Đức,  Tp. Hồ Chí Minh</a>
              </p>

              <p>
                <i class="bi bi-envelope" />
                <a href="mailto:hr@stacktech.org">hr@stacktech.org</a>
              </p>

              <p>
                <i class="bi bi-telephone" />
                <a href="mailto:info@stacktech.org">info@stacktech.org</a>
              </p>

              <p v-show="false">
                +65 9782 6016
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <div class="footer-copyright">
      <p>&copy; Stack Tech - All Rights Reserved</p>
    </div>
  </div>
</template>

<script>
import addressMap from "../mixins/jumpMap.js"
export default {
  name: "GlobalFooter",
  mixins: [addressMap],
  data() {
    return {
      activeTab: 0,
      tabList: [
        {
          name: "Home",
          routeName: "home",
        },
        {
          name: "About Us",
          routeName: "aboutUs",
        },
        {
          name: "Our Solutions",
          routeName: "ourSolutions",
        },
        {
          name: "Join Us",
          routeName: "joinUs",
        },
        {
          name: "Contact Us",
          routeName: "contactUs",
        },
      ],
    };
  },
  methods: {
    handleClickTab(item, index) {
      this.activeTab = index;
      sessionStorage.setItem("activeTab", this.activeTab);
      this.$emit('handleClickFooter', this.activeTab);
      this.$router.push({
        name: item.routeName,
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
