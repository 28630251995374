<template>
  <nav
    class="navbar navbar-expand-lg"
    :class="
      btnFlag
        ? 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center nav-sticky'
        : 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center'
    "
  >
    <div class="container-fluid">
      <!-- START OF LOGO --><a
                              class="navbar-brand"
                              @click="handleClickLogo"
                            ><img
                              alt="logo"
                              src="../assets/images/logo.jpg"
                            >
                            </a>
      <!-- END OF LOGO -->
      <!-- START OF MOBILE TOGGLER --><button
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler collapsed"
        data-bs-target="#navbarNav"
        data-bs-toggle="collapse"
        type="button"
        @click="showNav"
      >
        <i class="bi bi-list" />
      </button>
      <!-- END OF MOBILE TOGGLER -->
      <!-- START OF NAVBAR -->
      <div
        id="navbarNav"
        class="collapse navbar-collapse"
        :class="showNavMenu ? 'show' : ''"
      >
        <ul class="navbar-nav">
          <li
            v-for="(item, index) in tabList"
            :key="index"
            class="nav-item"
            @click="handleClickTab(item, index)"
          >
            <a
              aria-current="page"
              :class="activeTab === index ? 'active' : ''"
              class="nav-link"
            >{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <!-- END OF NAVBAR -->
    </div>
  </nav>
</template>

<script>
export default {
  name: "GlobalHeader",
  props: {
    footerActiveTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      btnFlag: false,
      showNavMenu: false,
      activeTab: Number(sessionStorage.getItem("activeTab")) || 0,
      tabList: [
        {
          name: "Home",
          routeName: "home",
        },
        {
          name: "About Us",
          routeName: "aboutUs",
        },
        {
          name: "Our Solutions",
          routeName: "ourSolutions",
        },
        {
          name: "Join Us",
          routeName: "joinUs",
        },
        {
          name: "Contact Us",
          routeName: "contactUs",
        },
      ],
    };
  },
  watch: {
    footerActiveTab(newVal, oldVal) {
      this.activeTab = newVal;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
    if (sessionStorage.getItem("activeTab") !== "") {
      this.activeTab = Number(sessionStorage.getItem("activeTab"));
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    showNav() {
      this.showNavMenu = !this.showNavMenu;
    },
    handleClickLogo() {
      this.activeTab = 0;
      this.$router.push({
        name: "home",
      });
    },
    handleClickTab(item, index) {
      this.activeTab = index;
      this.showNavMenu = false;
      this.$router.push({
        name: item.routeName,
      });
      sessionStorage.setItem("activeTab", this.activeTab);
    },
    scrollToTop() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 50) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>

<style scoped></style>
