import Vue from 'vue'
import Router from 'vue-router'
import { BasicLayout } from '@/layouts';

import Home from '../pages/Home.vue'
import AboutUs from '../pages/AboutUs.vue'
import OurSolutions from '../pages/OurSolutions.vue'
import JoinUs from '../pages/JoinUs.vue'
import ContactUs from '../pages/ContactUs.vue'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: BasicLayout,
            redirect: '/home/index',
            children: [
                {
                    path: '/home',
                    name: 'home',
                    component: { render: h => h('router-view') },
                    redirect: '/home/index',
                    children: [{
                        path: '/home/index',
                        component: Home,
                    }]
                },

            ]
        },
        {
            path: '/AboutUs',
            name: 'aboutUs',
            component: BasicLayout,
            redirect: '/aboutUs/index',
            children: [
                {
                    path: '/aboutUs',
                    name: 'aboutUs',
                    component: { render: h => h('router-view') },
                    redirect: '/aboutUs/index',
                    children: [{
                        path: '/aboutUs/index',
                        component: AboutUs,
                    }]
                },

            ]
        },
        {
            path: '/OurSolutions',
            name: 'ourSolutions',
            component: BasicLayout,
            redirect: '/aboutUs/index',
            children: [
                {
                    path: '/ourSolutions',
                    name: 'ourSolutions',
                    component: { render: h => h('router-view') },
                    redirect: '/ourSolutions/index',
                    children: [{
                        path: '/ourSolutions/index',
                        component: OurSolutions,
                    }]
                },

            ]
        },
        {
            path: '/joinUs',
            name: 'joinUs',
            component: BasicLayout,
            redirect: '/joinUs/index',
            children: [
                {
                    path: '/joinUs',
                    name: 'joinUs',
                    component: { render: h => h('router-view') },
                    redirect: '/joinUs/index',
                    children: [{
                        path: '/joinUs/index',
                        component: JoinUs,
                    }]
                },

            ]
        },
        {
            path: '/contactUs',
            name: 'contactUs',
            component: BasicLayout,
            redirect: '/contactUs/index',
            children: [
                {
                    path: '/contactUs',
                    name: 'contactUs',
                    component: { render: h => h('router-view') },
                    redirect: '/contactUs/index',
                    children: [{
                        path: '/contactUs/index',
                        component: ContactUs,
                    }]
                },

            ]
        }
    ]
})

router.beforeEach((to, from, next) => {
    // 路由跳转后，让页面回到顶部
    // chrome
    document.body.scrollTop = 0; // firefox
    document.documentElement.scrollTop = 0; // safari
    window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next();
});

export default router