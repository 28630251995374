import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'

import router from './router'
import store from './store'

// import VueAwesomeSwiper from 'vue-awesome-swiper'
// // //引入样式
// import '../src/assets/css/swiper.min.css'
// import './assets/css/swiper-3.4.2.min.css'
// Vue.use(VueAwesomeSwiper,)/* { 全局组件的默认选项 } */


// 引入css样式
import './plugins/bootstrapvue'                  // 引入 bootstrap-vue
import 'zico/css/zico.min.css'                   // zico 图标库 css
import 'github-markdown-css/github-markdown.css' // markdown css
// import './assets/css/animate.css'
// import './assets/css/style.css'

import Slick from 'slick-carousel';
Vue.prototype.$slick = Slick;
// 挂载 axios
Vue.prototype.$http = axios

Vue.config.productionTip = false
// 配置 Ajax 请求根地址
axios.defaults.baseURL = 'http://127.0.0.1:8000/api'
import * as VueGoogleMaps from 'vue2-google-maps'
 
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCEzVxERg-ggUgSjG0HiZNvkUaTYKBhMNc',
    libraries: 'places',
    // region: 'VI', // 这个地区自己定
    // language: 'en' // 这个语言自己定
  },
  installComponents: true
})

// 代码高亮
import hljs from 'highlight.js'
import 'highlight.js/styles/github.css'
Vue.directive('highlight', function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block) => {
    hljs.highlightBlock(block)
  })
})



new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
