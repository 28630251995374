<template>
  <div
    class="map-warpper"
    @click="jumpMap"
  >
    <img src="../assets/images/address-map.png">
  </div>
</template>
<script>
import addressMap from "../mixins/jumpMap.js"
export default {
  mixins: [addressMap]
}
</script>
<style lang="less" scoped>
.map-warpper {
  width: 100%;
  height: 280px;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
