import Vue from 'vue'
import { BootstrapVue, IconsPlugin} from 'bootstrap-vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '/style/themes/style.min.css'
import '../assets/css/main.css'
import '../assets/css/bootstrap.min.css'
import '../assets/css/animate.min.css'
import '../assets/css/normalize.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)